.message {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: white;
  color: #121212;
  font-size: 14px;
  max-width: 300px;
  animation: slideDown 0.5s ease-in-out forwards;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.dark message {
  background-color: red;
  color: white;
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.success {
  background-color: #52c41a;
}

.error {
  background-color: #ff4d4f;
}

.warning {
  background-color: #faad14;
}

.info {
  background-color: #1890ff;
}
